.table-responsive{
  thead {
    position: sticky;
    top: -1px;
    z-index: 1;
  }
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  right: 0;
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color:#EAECF0;
  border-radius: 8px;
  width: 10px;
  height: 10px;
}

::-ms-reveal {
  display: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.align-center {
  text-align: center;
}

.flex {
  display: flex;
  gap: 4px;
}

svg.sort-icon {
  border-radius: 4px;
  padding: 0 4px;
  box-sizing: content-box;
}

svg.sort-icon-dark {
  background: #98A2B3;
  border-radius: 4px;
  padding: 0 4px;
  box-sizing: content-box;
}

.react-datepicker-container {
  position: relative;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-top: 5px;
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker {
      filter: drop-shadow(rgba(0, 0, 0, 0.32) 0px 2px 8px);
      border: none;
      .react-datepicker__triangle {
          bottom: 1px;
          &::before {
            border-top: none;
          }
      }
      .react-datepicker__navigation--previous {
          left: 5px;
          top: 14px;
          span {
            &::before {
              border-color: #1D2939;
            }
          }
      }
      .react-datepicker__navigation--next {
          left: 40px;
          top: 14px;
          span {
            &::before {
              border-color: #1D2939;
            }
          }
      }
      .react-datepicker__header {
          background-color: #fff;
          border-bottom: none;
          &.react-datepicker__header--time {
            padding-bottom: 5px;
            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
              height: auto;
            }
          }
          .react-datepicker__current-month {
              height: 2.75rem;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              padding: 0 1rem;
          }
          .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
            display: flex;
            justify-content: flex-end;
            padding-bottom: 0.25rem;
            select {
              padding: 7px 0;
              border: none;
              background-color: #fff;
              &:focus-visible {
                outline: none;
            }
          }
        }
      }
      .react-datepicker__month-container {
          border: none;
          .react-datepicker__month {
              margin: 0.5rem;
              margin-bottom: 0.7rem;
              .react-datepicker__day {
                  border-radius: 1rem;
              }
              .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected, .react-datepicker__day--in-range {
                  background-color: #004ABC;
                  color: #fff;
                  &:hover {
                      color: #fff;
                  }
              }
          }
      }
  }
  .react-datepicker-component {
      width: 200px;
      font-size: 14px;
      height: auto !important;
      margin: 0 !important;
      border-radius: 8px;
      border: 1px solid #D0D5DD;
      background: inherit;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      padding: 8px 14px;
      font-style: normal;
      font-weight: 500;
      color: #475467;
      background-color: #fff;
      &::placeholder {
        opacity: 0.5; 
      }
      &:focus-visible {
          outline: none;
      }
  }
}

.publish-or-unpublish-modal .table-responsive {
  margin-bottom: 1rem;
}

.pb-4, .py-4 {
  padding-bottom: 1rem !important;
}
.pt-4, .py-4 {
  padding-top: 1rem !important;
}